@import "./variable.scss";
@import "./fonts.scss";
// Bootrsrap Overwrite CSS
@media (min-width: 1200px) {
  .container {
    max-width: 1300px;
  }
}
a {
  color: #030dcf;
}
.btn {
  font-weight: $bold;
  font-size: 16px;
  line-height: 45px;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 0px 26px; 
  // font-weight: $exbold;
  // font-size: 14px;
  // line-height: 33px;
  // border-radius: 4px;
  // min-height: 50px;  
  &:focus {
    box-shadow: none;
  }
  &.disabled,
  &:disabled {
    cursor: pointer;
    background-color: #666666;
    border-color: #666666;
    opacity: 1;
    &:hover{
      background-color: #666666;
      border-color: #666666;
      opacity: 0.65;
    }
  }
}
a.btn {
  font-weight: $bold;
  font-size: 16px;
  line-height: 45px;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 0px 26px; 
}

.btn-xs {
  min-width: 90px;
  min-height: 36px;
  line-height: 31px;
  padding: 0 12px;
}
.btn-sm {
  min-width: 105px;
  min-height: 48px;
}
.btn-lg {
  min-height: 66px;
  font-weight: $bold;
  font-size: 18px;
  line-height: 22px;
}
.btn-md {
  min-height: 48px;
  width: 290px;
  font-weight: $bold;
  font-size: 18px;
  line-height: 22px;
}

.btn-dark {
  background-color: $black;
  border-color: $black;
  &:focus,
  &:hover {
    background-color: $black;
    border-color: $black;
    box-shadow: none;
  }
}
.btn-gray {
  background-color: $gray;
  border-color: $gray;
  color: #fff;
  &:focus,
  &:hover {
    color: #fff;
    background-color: $gray;
    border-color: $gray;
    box-shadow: none;
  }
}
.btn-red {
  background: #EA1414;
  color: #fff;
  border-color: $white;
  &:focus,
  &:hover {
    background-color: #EA1414;
    color: #fff;
    border-color: $white;
    box-shadow: none;
  }
}
 
.btn-dark:not(:disabled):not(.disabled).active:focus, .btn-dark:not(:disabled):not(.disabled):active:focus, .show>.btn-dark.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-link {
  color: $black;
  &:hover,
  &:focus {
    color: $black;
    text-decoration: none;
  }
}

.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle:focus,
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  color: #fff;
  background-color: $blue;
  border-color: $blue;
  box-shadow: none;
}

.btn-primary {
  color: #fff;
  background-color: $blue;
  border-color: $blue;
  &:focus,
  &:hover {
    color: #fff;
    background-color: $lightBlue;
    border-color: $lightBlue;
    box-shadow: none;
  }
}
.btn-secondary {
  background: rgba(0, 0, 0, 0.6);
  border: 0px;
  &:hover,
  &:hover{
    background: rgba(0, 0, 0, 0.6);
  }
}
.btn-outline-primary {
  color: $blue;
  border-color: #dcdfe3;
  &:active,
  &:focus,
  &:hover {
    color: #fff;
    background-color: $blue;
    border-color: $blue;
    box-shadow: none;
  }
}
.btn-outline-black {
  border: 2px solid #000000;
  border-radius: 4px;
  background-color: transparent;
  color: #000;
  &:active,
  &:focus,
  &:hover {
    color: #fff;
    background-color: #000;
    border-color: #000;
    color: #fff;
    box-shadow: none;
  }
}
.btn-outline-light {
  &:active,
  &:focus,
  &:hover {
    color: $blue;
    background-color: $white;
    border-color: $white;
    box-shadow: none;
  }
}

.btn-next{
  svg{
    transform: rotate(180deg);
    // margin-right: 3px;
  }  
}
// .btn-previous{
//   margin-left: 3px;
// }
.btn-previous,.btn-next{
  display: flex;
  align-items: center;
  justify-content: center;
  svg{
    height: 23px;
  }
}

.label-text {
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #030dcf;
  margin-bottom: 0;
}

.form-group {
  margin-bottom: 20px;
}
.form-control {
  border: 2px solid #E5E5E5;
  min-height: 66px;
  border-radius: 4px;
  color: $black;
  padding: .375rem 14px;
  font-size: 16px;
  line-height: 19px;
  &:focus {
    border-color: $black;
    box-shadow: none;
  }
  &[type='number']::-webkit-inner-spin-button,
  &[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    margin: 0;
  }
  &::placeholder {
    color: #000;
  }
}
.select-form-sm{
  .form-control{
    padding-top: 19px !important;
    min-height: 45px;
    font-size: 14px;
  }
  .material-textfield-label{
    top: 7px !important;
  }
}
.form-control-sm {
  min-height: 45px;
  font-size: 14px;
}
.form-control:disabled,
.form-control[readonly] {
  background-color: #f7f7f7;
  // padding-top: 28px;
}
.invalid-control {
  border: 1px solid $red;
}
.invalid-feedback {
  display: block;
  font-size: 100%;
  &.big-font{
    font-size:16px ;
  }
}
.ant-drawer-close,
button.close {
  // background: url('../images/cancel.svg') no-repeat;
  background-position: center;
  background-size: 25px;
  opacity: 1;
  > .anticon.anticon-close,
  > span {
    opacity: 0;
  }
  &:focus {
    outline: none;
  }
}
.disable__items{
  background-image: none !important;
  background-color: #fafafa;
  border: 2px solid #E5E5E5;
  min-height: 66px;
  border-radius: 4px;
  color: #000;
  padding: 0.375rem 14px;
  font-size: 16px;
  .title{
    font-family: 'Pangram';
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    margin-bottom: 0px;
  }
  .sub-title{
    font-family: 'Pangram';
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
    margin-bottom: 0px;
    padding-top: 10px;
  }
  .red-text {
    color: #F90B0B;
  }
}
.card {
  border: 0;
  box-shadow: 5px 5px 20px #E5E5E5;
  border-radius: 6px;
  &:last-child {
    margin-bottom: 0;
  }
  &.none-shadow {
    box-shadow: none;
  }
  &.transparent {
    background-color: transparent;
  }
  .card-header {
    padding: 18px 42px;
    background-color: #fff;
    border-bottom: 1px solid #E5E5E5;
    min-height: 65px;
    border-radius: 6px 6px 0 0;
    @media (max-width: 667px){
      padding: 18px 15px;
    }
    .card-title {
      font-family: $Pangram;
      font-weight: $bold;
      font-size: 22px;
      line-height: 30px;
      color: $black;
      margin: 0;     
      span{
        font-weight: normal;
        margin-left: 8px;
      }
      &.card-title-sm {
        font-size: 16px;
        line-height: 19px;
      }
    }
    .card-title-sm {
      font-size: 16px;
      line-height: 22px;
      margin: 8px 0 0 0px;
      a{
        color: #000;
        text-decoration: none;
      }
    }
  }
  .card-body {
    padding: 27px 42px 47px;
    @media (max-width: 767px){
      padding: 20px 15px;
    }
  }
}
.card__body-inner{
  display: flex;
  .card__left{
    width: 100%;
    max-width: 100%;
  }
  .card__right{
    width: 100%;
    min-width: 277px;
    max-width: 277px;
  }
}
.card__thum-item{
  margin-bottom: 15px;
}
.thumb-preview-title{
  font-weight: $bold;
  font-size: 14px;
  line-height: 23px;
  color: #000000;
  margin-bottom: 8px;
}
.card__list{
  display: flex;
  margin-bottom: 23px;
  padding: 0px;
  @media (max-width:767px) {
    flex-direction: column;
  }
  li{
    width: 100%;
    max-width: 280px;
    margin-right: 45px;
    @media (max-width:767px) {
      max-width: 100%;
      margin-right: 0;
      margin-bottom: 20px;
    }
  }
}
.card__list_custom{
  display: flex;
  margin-bottom: 23px;
  padding: 0px;
  @media (max-width:767px) {
    flex-direction: column;
  }
  li{
    width: 100%;
    max-width: 630px;
    margin-right: 45px;
    @media (max-width:767px) {
      max-width: 100%;
      margin-right: 0;
      margin-bottom: 20px;
    }
  }
}
.fullspan {
  span {
    @media (max-width:767px) {
      &:last-child {
        display: block;
        margin: 5px 0 20px 0;
      }
    }
  }
}
 
.card__scale-list{
  li{
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px; 
    margin-bottom: 20px;
    color: #000000;   
    span {
      // &:first-child {
      //   @media (max-width:767px) {
      //     display: block;
      //     margin: 0 0 0 0;
      //   }
      // }
      // &:last-child {
      //   span {
      //     @media (max-width:767px) {
      //       display: inline-block;
      //       margin: 5px 0 20px 0;
      //     }
      //   }
      // }
      // @media (max-width:767px) {
      //   margin: 5px 0 20px 0;
      // }
    }
  }
  .total__order-amount{
    font-size: 20px;
    line-height: 36px;
  }
  .company__driver-title{
    margin-bottom: 15px;
  }
}

.ordercard__list{
  li{
    @media (max-width: 992px) { 
      flex-direction: column;
      align-items: self-start;
      span + span{
        margin-top: 10px;
      }
    }
  }
}


.navbar-light .navbar-toggler {
  border-color: rgba(0, 0, 0, 0);
}
// Bootrsrap Overwrite CSS

// Genral Css
.mt-20{
  margin-top: 20px;
}
.mb-20{
  margin-bottom: 20px;
}
.mb-33{
  margin-bottom: 33px;
}
.cursor-pointer {
  cursor: pointer;
}
.text-red{
  color: #F91111;
} 
.bg-green{
  background-color: $green !important;
}
.bg-yellow{
  background-color: $yellow !important;
}
.underline{
  text-decoration: underline;
  cursor: pointer;
}
.display-team-status {
  margin-bottom: 20px;
  display: flex;
  align-items: center;  
  button {
    margin: 0 18px;
  }  
  p{
    margin: 0px;
  }
}
.ant-switch.ant-switch-gray-bg{
  background-color: #00000040;
}
.ant-switch {
  background-color: #ff0000;
  &.ant-switch-checked {
    background-color: $yellow;
  }
}
.on-of-text {
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    margin: 0px;
}
.ant-message {
  color: $white;
  // top: inherit;
  left: inherit;
  right: 0;
  bottom: 0;
  width: auto;
  .ant-message-notice {
    padding: 10px;
    text-align: left;
  }
  .ant-message-notice-content {
    padding: 18px 24px;
    background: #323232;
    min-width: 288px;
    text-align: left;
    border-radius: 0;
    .anticon {
      display: none;
      color: $white;
      top: -2px;
    }
  }
}
.ant-pagination {
  margin: 20px 0px;
  .ant-pagination-disabled {
    display: none;
  }
  .ant-pagination-item {
    font-family: $Pangram;
    &:hover,
    &:focus {
      border-color: $yellow;
    }
  }
  .ant-pagination-item-active {
    border-color: $yellow;
    color: $navy;
    background: $yellow;
    &:active a,
    &:focus a {
      color:  $navy;
    }
  }
  .ant-pagination-jump-prev,
  .ant-pagination-jump-next {
    .ant-pagination-item-link-icon {
      color: $yellow;
    }
  }
  .anticon {
    vertical-align: text-top;
  }
  .ant-pagination-prev,
  .ant-pagination-next {
    .ant-pagination-item-link {
      // border-color: #00c577;
      &:hover,
      &:focus {
        border-color: $yellow;
      }
      &:hover svg{
        color: $yellow;
      }
    }    
  }
}
ul {
  @include list-style-none;
}

.text-black {
  color: $black;
}
.text-white {
  color: $white;
}
.text-green {
  color: $green;
}
.text-gray {
  color: $gray;
}

.font-16 {
  font-size: 16px;
}
.font-18 {
  font-size: 18px;
}
.font-20 {
  font-size: 20px;
}
.font-22 {
  font-size: 22px;
}
.font-24 {
  font-size: 24px;
}
.font-800 {
  font-weight: 800;
}
.font-700 {
  font-weight: 700;
}
.font-600 {
  font-weight: 600;
}
.w-120 {
  min-width: 120px;
}
.w-150 {
  min-width: 150px;
}
.w-180 {
  min-width: 180px;
}

//React lightbox css
.ril-prev-button, .ril-toolbar, .ril-next-button {
  display: none !important;
}
//React lightbox css end

// React Modal Css
.ReactModal__Body--open{
  overflow: hidden;
}
.ReactModal__Overlay.ReactModal__Overlay--after-open {
  overflow-x: hidden;
  overflow-y: auto;
}
.ReactModal__Overlay.ReactModal__Overlay--after-open::before {
  content:'';
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.60);
  z-index: 99;
}
.ReactModal__Overlay {
  z-index: 1050;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
  background-color: transparent !important;
  z-index: 1072;
  display: flex;
  justify-content: center;
}
.ReactModal__Content.ReactModal__Content--after-open{
  // position: static  !important;
  top: auto !important;
  left: auto !important;
  right: auto !important;
  bottom: auto !important;
  border: none !important;
  background: transparent  !important;
  overflow: visible !important;
  border-radius: 0 !important;
  outline: none !important;
  padding: 0 !important;
  // width: auto !important;
  width: 100% !important;
  margin-right: 0 !important;
  transform: none !important;
  min-height: calc(100%);
  display: flex;
  align-items: center;
}
.react-modal-dialog  {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  border-radius: .3rem;
  outline: 0;
  overflow: initial !important;
  transition: all 0.2s ease;
  z-index: 99;
}
.react-modal-dialog {
  max-width: 600px;
  margin: 1.75rem auto;
  &.react-modal-dialog-xs {
    max-width: 420px;
  }
  &.react-modal-dialog-sm {
    max-width: 700px;
  }
  &.react-modal-dialog-md {
    max-width: 960px;
  }
  &.react-modal-dialog-lg {
    max-width: 1024px;
  }
  &.react-modal-dialog-xl {
    max-width: 1200px;
  }
}
.react-modal-dialog.react-modal-dialog-centered {
  min-height: calc(100% - 3.5rem);
}
.react-modal-dialog-centered {
  min-height: calc(100% - 1rem);
}
.react-modal-header {
  padding: 20px 20px 20px 47px;
  border-bottom: 1px solid #E5E5E5;
  min-height: 67px;
  .react-modal-title {
    font-weight: $bold;
    font-size: 22px;
    line-height: 26px;
    color: $black;
    margin: 0px;
    @media (max-width: 767px) {
      font-size: 18px;
    }
    &.modal-title-sm {
      font-size: 18px;
      line-height: 22px;
    }
  }
  .react-modal-close {
    position: absolute;
    top: 9px;
    right: 10px;
    z-index: 10;
  }
}
.react-modal-body {
  padding: 25px 47px 41px 47px;
}
.company__driver-title{
  .title{
    font-weight: $bold;
    font-size: 14px;
    line-height: 23px;
    color: #000000;
    margin-bottom: 11px; 
    @media (max-width: 767px) {
      margin-bottom: 0; 
    }
  }
  .sub__title{
    font-size: 16px;
    line-height: 23px;  
    color: #000000;
    margin: 0px;
  }
}
// React Modal Css

// Genral Css
.impersonate {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  .impersonate-wrapper {
    background: $blue;
    color: #ffffff;
    font-size: 16px;
    font-weight: 500;
    height: 59px;
    line-height: 20px;
    padding: 8px 15px;
    text-align: center;
    @media (min-width: 584px) {
      padding: 20px 18px;
    }
    .impersonate-link {
      color: $white;
      margin-left: 5px;
      &:hover,
      &:focus {
        text-decoration: underline;
        color: $white;
      }
    }
  }
}

// Common Css
.ant-tooltip-inner {
  background-color: $black;
  padding: 7px 12px;
}
.ant-select-focused .ant-select-selection,
.ant-select-selection:active,
.ant-select-selection:focus {
  box-shadow: none;
}
.custom-select-50{  
  .custom-select{
    min-height: 50px;
    padding-top: 20px !important;
  }
  .custom-input{
    min-height: 50px;
    padding-top: 20px !important;
  }
  .material-textfield-label{
    top: 7px !important;
  }
}
.custom-select {
  background: #fff url(../images/select-icon.svg) no-repeat right 22px center;
  background-size: 16px;
  padding-right: 56px;
  &:focus {
    border-color: $black;
    box-shadow: none;
  }
}
.custom-select-spiner{
  position: relative;
  display: inline-block;
  min-width: 165px;
  .ant-spin-spinning {
    position: absolute;
    display: inline-block;
    opacity: 1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .custom-select{
    width: 100% !important;
    max-width: 100% !important;
  }
}
.select__custom {
  width: 100%;
  &.br-left-colored {
    .ant-select-selection--single {
      border-left: 5px solid $green;
    }
  }
  &.large-has-shadow .ant-select-selection {
    box-shadow: 0px 11px 20px #e5eced;
  }
  &.has-shadow .ant-select-selection {
    background: #ffffff;
    border: 0px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.09);
    border-radius: 2px;
  }
  &.ant-select-open .ant-select-selection {
    box-shadow: none;
  }
  .ant-select-selection {
    background: #ffffff;
    border: 1px solid #e5eced;
  }
  .ant-select-selection--single {
    height: 40px;
  }
  .ant-select-selection__rendered {
    margin-right: 21px;
    margin-left: 0.75rem;
    line-height: 40px;
    .ant-select-selection-selected-value {
      font-size: 14px;
      color: #708492;
    }
    + .ant-select-arrow {
      right: 20px;
      margin-top: -4px;
    }
  }
}
.select__custom--options {
  .ant-select-dropdown-menu-item {
    padding: 10px 0.75rem;
    font-size: 14px;
    color: #000;
    white-space: pre-wrap;
    line-height: 20px;
    &:hover {
      color: #fff;
      background-color: $blue;
    }
  }
}

// Common Css

// Header
.profile--dropdown {
}
.ant-dropdown.profile--dropdown--overlay {
  &::after {
    content: '';
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 12px solid #E5E5E5;
    position: absolute;
    top: 8px;
    right: 6px;
  }
  .ant-dropdown-menu {
    background: #E5E5E5;
    box-shadow: none;
    border-radius: 6px;
    margin-top: 20px;
    .ant-dropdown-menu-item {
      padding: 11px 20px;
      &:hover {
        background: rgba(0, 0, 0, 0.02);
      }
      > a,span {
        font-weight: $medium;
        font-size: 14px;
        line-height: 20px;
        color: $black;
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}
// antd dropdown in popups
.ant-dropdown.profile--dropdown--overlay__inpopup {
  z-index: 9999;
  &::after {
    content: '';
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 12px solid #E5E5E5;
    position: absolute;
    top: 8px;
    right: 6px;
  }
  .ant-dropdown-menu {
    background: #E5E5E5;
    box-shadow: none;
    border-radius: 6px;
    .ant-dropdown-menu-item {
      padding: 11px 20px;
      &:hover {
        background: rgba(0, 0, 0, 0.02);
      }
      > a,span {
        font-weight: $medium;
        font-size: 14px;
        line-height: 20px;
        color: $black;
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}
// Mobile Drawer
.mobile-drawer {
  .ant-drawer-wrapper-body {
    height: calc(100% - 40px);
    overflow-x: hidden;
    overflow-y: auto;
  }
  .ant-drawer-content {
    z-index: 1010;
  }
  .ant-drawer-content-wrapper {
    max-width: 480px;
    overflow: hidden;
  }
  .ant-drawer-body {
    padding: 0;
  }
  .mobile-drawer-container {
    // height: calc(100vh - 40px);
    // overflow: auto;
  }
  .profile-wrapper {
    position: relative;
    margin-bottom: 20px;
    text-align: center;
  }
  .btn-close-drawer {
    position: absolute;
    left: -30px;
    top: 0;
  }
  .profile-banner {
    height: 184px;
    overflow: hidden;
    user-select: none;
    > img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
  .profile-wrapper--avatar {
    width: 160px;
    height: 160px;
    border-radius: 100px;
    background-color: #f8f8f8;
    border: 4px solid #ffffff;
    margin: 0 auto;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -50px;
    overflow: hidden;
    > img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
  .profile-wrapper--name {
    font-family: $Pangram;
    font-size: 32px;
    line-height: 38px;
    color: #181818;
    text-align: center;
    margin: 47px 0 20px;
  }
  .mobile-nav-list {
    > li {
      border-bottom: 1px solid #dcdfe3;
      &:first-child {
        border-top: 1px solid #dcdfe3;
      }
      .mobile-nav-list-item,
      a {
        padding: 10px 15px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-height: 64px;
        cursor: pointer;
        &:hover,
        &:focus {
          text-decoration: none;
        }
      }
      .title {
        font-family: $Pangram;
        font-size: 20px;
        line-height: 24px;
        color: #181818;
      }
      .count {
        font-weight: bold;
        font-size: 22px;
        line-height: 28px;
        text-align: right;
        color: #1200d8;
        > img {
          cursor: pointer;
        }
      }
    }
  }
  .drafts-reviews-list {
    margin-bottom: 15px;
    > li {
      cursor: pointer;
      border-bottom: 1px solid #dcdfe3;
      padding: 10px 15px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      &:first-child {
        border-top: 1px solid #dcdfe3;
      }
      a {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
    .reviews-link,
    .drafts-link {
      font-size: 16px;
      line-height: 24px;
      text-decoration-line: underline;
      color: $blue;
      cursor: pointer;
    }
  }
  .btn-logout {
    width: 100%;
    border-radius: 0;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    background-color: #fff;
  }

  .profile-container {
    max-width: 300px;
    margin: 0 auto;
    .form-group {
      margin-bottom: 25px;
    }
    .profile-edit-cta {
      .btn-primary {
        @media (max-width: 766px) {
          margin-right: 10px;
        }
        @media (min-width: 767px) {
          margin-bottom: 16px;
        }
      }
    }
  }
}
// Mobile Drawer

.modal-search-box {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  z-index: 1043;
  height: 80px;
  border-bottom: 1px solid #dcdfe3;
  transition: all 0.3s ease;
  transform: translateY(-80px);
  @media (max-width: 768px) {
    height: 60px;
  }
  &.open {
    transform: translateY(0);
  }
  .modal-search-box-input {
    width: calc(100% - 60px);
    font-family: $Pangram;
    font-size: 34px;
    line-height: 48px;
    height: 79px;
    color: #76838f;
    // background: url(../images/search.svg) no-repeat;
    background-position: 41px center;
    padding-left: 100px;
    border-color: transparent;
    @media (max-width: 767px) {
      height: 60px;
      font-size: 20px;
      background-position: 15px center;
      padding-left: 55px;
    }
    &:focus,
    &:active {
      border-color: transparent;
    }
  }
  .modal-search-box-button {
    min-width: 60px;
  }
}
.search-wrapper-open-container {
  display: block;
}
.search-wrapper-open-container {
  background-color: #fff;
  border-radius: 2px;
  // box-shadow: 0 14px 20px rgba(0, 0, 0, 0.5);
  display: none;
  list-style-type: none;
  margin-top: 0;
  max-height: 400px;
  overflow-x: hidden;
  overflow-y: auto;
  position: absolute;
  text-align: left;
  top: 100%;
  width: 100%;
  z-index: 10;
  .options-menu-typeahead-header {
    padding: 8px 16px 8px 50px;
    position: relative;
    .options-menu-typeahead-icon {
      position: absolute;
      top: 7px;
      left: 12px;
    }
  }
  .search-wrapper-options-menu {
    margin-bottom: 0;
    .search-wrapper-options-menu-item {
      a,
      &.search-wrapper-options-menu-item-other {
        color: $black;
        display: block;
        padding: 8px 16px 8px 50px;
        cursor: pointer;
        &:hover {
          color: $blue;
          background-color: #f9f9f9;
          text-decoration: none;
        }
      }
      .options-menu-item-title,
      .options-menu-item-subtitle {
        margin: 0;
      }
      .options-menu-item-subtitle {
        font-size: 14px;
        line-height: 1.5em;
      }
    }
  }
}
// Header

.head-section-header {
  min-height: 80px;
  padding: 20px 45px;
  position: relative;
  z-index: 1;
  .head-section-title {
    font-weight: $bold;
    font-size: 22px;
    line-height: 26px;
    color: #000000;
  }
  .head-section-cta {
    margin-left: auto;
    // .btn-back {
    //   padding: 0px;
    // }
  }
}


// material-Lable style
.material-textfield {
  position: relative;
  .material-textfield-label {
    // font-weight: $bold;
    // font-size: 14px;
    // line-height: 17px;
    // color: #000000;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
    position: absolute;
    pointer-events: none;
    left: 14px;
    top: 23px;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
    margin-bottom: 0;
    @media (max-width: 767px) {
      font-size: 14px;
    }
    span{
      color: red;
    }
  }
  .material-textfield-input, input.form-control {
    &:focus,
    &:valid {
      padding-top: 28px;
    }
    &:disabled ~ .material-textfield-label,
    &:valid ~ .material-textfield-label,
    &:focus ~ .material-textfield-label {
      top: 12px;
      left: 14px;
      font-weight: $bold;
      font-size: 12px;
      line-height: 14px;
      color: $black;
    }
    &:disabled ~ .material-textfield-label {
      color: #ccc;
    }
  }
}

// material-Lable style
.input-active-global .material-textfield-input {
  padding-top: 19px;
}

.input-active-global .material-textfield-label {
  top: 9px !important;
  left: 14px;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
}
// Checkbox
.checkbox-list{
  &.vertical {
    > li {
      margin-bottom: 17px;
    }
  }
  &.horizontal {
    > li {
      display: inline-block;
      margin-right: 21px;
    }
  }
}
.custom-checkbox {
  display: block;
  position: relative;
  padding-left: 42px;
  margin-bottom: 0;
  cursor: pointer;
  color: $black;
  font-size: 14px;
  line-height: 30px;
  .custom-checkbox--input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
}
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 30px;
  width: 30px;
  background-color: #fff;
  border: 2px solid #C4C4C4;
  border-radius: 4px;
}
.custom-checkbox {
  .custom-checkbox--input:checked ~ .checkmark {
    background-color: $black;
    border: 2px solid $black;
  }
}
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}
.custom-checkbox {
  .custom-checkbox--input:checked ~ .checkmark:after {
    display: block;
  }
  .checkmark:after {
    left: 10px;
    top: 5px;
    width: 6px;
    height: 13px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
}

.custom-checkbox-line {
  line-height: 24px;
  display: inline-block;
  vertical-align: middle;
  .checkmark {
    height: 24px;
    width: 24px;
    background-color: #fff;
    border: 1px solid #000;
    border-radius: 0;
    &:after {
      left: 7px;
      top: 2px;
    }
  }
}
// Checkbox

// Custom Radio
.custom-radio-radius{
  .checkmark{
    border-radius: 50px;
  }
}
.custom-radio {
  display: block;
  position: relative;
  padding-left: 32px;
  margin-bottom: 0;
  cursor: pointer;
  font-size: 14px;
  line-height: 20px;
  color: #7a838d;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  .custom-radio--input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
}
// drawer Start
.select_driver-list{
  background: #FFFFFF;
  // box-shadow: 5px 5px 20px #E5E5E5;
  border-radius: 6px;
  overflow-y: auto;
  max-height: calc(100vh - 290px);
  .active{
    background-color: #000;
    .driver__name{
      color: #fff;
    }
    .custom-checkbox--input:checked ~ .checkmark{
      background-color: #fff;
      border: 1px solid #000;
    }
    .checkmark{
      &:after{
        border: solid #000 !important;
        border-width: 0 2px 2px 0 !important;
      }
    }
  }
  li{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 23px;
    position: relative;
    border-bottom: 1px solid #E5E5E5;
    .thumb-preview{
      margin-right: 28px;
    }
    .driver__name{
      font-weight: $medium;
      font-size: 16px;
      line-height: 92px;
      color: #000;
      margin: 0px;
    }
  }
}
.select__driver-drawer{
  .select__drawer-btm-btn{
    position: absolute;
    bottom: 17px;
    width: 100%;
    max-width: 90%;
  }  
}
// drawer End
.custom-radio--check {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background: #ffffff;
  border: 1px solid #dcdfe3;
  border-radius: 50%;
}

.custom-radio {
  .custom-radio--input:checked ~ .custom-radio--check {
    background-color: #fff;
  }
}

.custom-radio--check:after {
  content: '';
  position: absolute;
  display: none;
}
.custom-radio {
  .custom-radio--input:checked ~ .custom-radio--check:after {
    display: block;
  }
  .custom-radio--check:after {
    top: 4px;
    left: 4px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: $blue;
  }
}
// Radio Text

// Custom Redia Btn
.custom-radio-btn {
  display: inline-block;
  position: relative;
  width: 184px;
  height: 70px;
  margin-bottom: 0;
  cursor: pointer;
  font-size: 14px;
  line-height: 20px;
  color: #7a838d;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  @media (max-width: 767px) {
    width: 130px;
    height: 60px;
  }
  .custom-radio-btn-title {
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .custom-radio-btn--input:checked ~ .custom-radio-btn-title{
    color: #fff;
  }
  .custom-radio-btn--input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  .custom-radio-btn--check {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #ffffff;
    border: 1px solid #E5E5E5;
    border-radius: 6px;
  }
  .custom-radio-btn--input:checked ~ .custom-radio-btn--check {
    background-color: #fff;
  }
  .custom-radio-btn--check:after {
    content: '';
    position: absolute;
    display: none;
  }
  .custom-radio-btn--input:checked ~ .custom-radio-btn--check:after {
    display: block;
  }
  .custom-radio-btn--check:after {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $blue;
    border-radius: 6px;
    border: 1px solid $blue;
  }
}
// Custom Redia Btn

// custom checkbox select Start

.custom__checkbox-select{
  position: relative;
  margin-bottom: 20px;
  .custom-select{
    padding-top: 30px;
    cursor: pointer;
  }
	.common__check-dropdown-list{
    top: 66px;
    left: 0px;
    z-index: 9;
    position: absolute;
    border: 0;
    border-radius: 6px;
    box-shadow: 5px 5px 20px #E5E5E5;
    background-color: #fff;
    width: 100%;
    padding: 15px;
    .common__check-dropdown-list-items{
      margin-bottom: 7px;
      // .custom-checkbox{
      //   width: 100%;
      // }
    }
  }
  label.material-textfield-label{
    top: 12px;
    left: 14px;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    color: #000;
  }
  .close-dropdown{
    display: none;
  }
  .disabled{
    .custom-select{
      background-color: #938c8c17;
      opacity: 1;
    }
    .custom-select,.material-textfield-label{
      color: #ccc;
    }
  }
}
// custom checkbox select End

.more__menu {
  .ant-dropdown-menu {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
    border-radius: 6px;
    .ant-dropdown-menu-item {
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      color: #000000;
      &.activate {
        color: #00e700;
      }
      &.disable {
        color: #d0021b;
      }
    }
  }
}

.card-details {
  .title {
    font-weight: $exbold;
    font-size: 12px;
    line-height: 17px;
    letter-spacing: 0.5px;
    color: #000000;
  }
  .subtitle {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.428571px;
    color: #000000;
  }
  .btn-apply {
    position: absolute;
    top: 5px;
    right: 6px;
    z-index: 10;
  }
  .card-info-list {
    font-weight: $medium;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: 0.5px;
    color: #000000;
    > li {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .bill-btn-wrapper {
    display: flex;
  }
  .bill-btn {
    width: 100%;
    border: 1px solid #000000;
    border-radius: 6px;
    margin-right: 37px;
    padding: 9px;
    &:last-child {
      margin-right: 0;
    }
    .bill-month {
      font-weight: $exbold;
      font-size: 12px;
      line-height: 21px;
    }
    .bill-price,
    .bill-year {
      font-weight: $normal;
      font-size: 14px;
      line-height: 21px;
      text-align: center;
      letter-spacing: 0.5px;
      color: #000000;
    }
  }
}
.info-tooltip{
  position: absolute;
  top: 50%;
  right: 18px;
  transform: translateY(-50%);
  z-index: 10;
  padding: 0;
  min-height: inherit;
  line-height: normal;
}

.avatar {
  display: inline-block;
  width: 40px;
  height: 40px;
  border-radius: 50px;
  overflow: hidden;
  background-color: #f9f9f9;
  position: relative;
  cursor: pointer;
  > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .upload-avatar {
    &[type=file] {
      cursor: pointer;
      width: 100%;
      height: 100%;
      overflow: hidden;
      font-size: 0;
      position: absolute;
      z-index: 1;
    }
    &[type=file]:before {
      width: 100%;
      height: 100%;
      content: '';
      display: inline-block;
    }
    &[type=file]::-webkit-file-upload-button {
      visibility: hidden;
    }
  }
}

// Dropzone
.dropzone {
  width: 100%;
  cursor: pointer;
  display: inline-block;
  margin-bottom: 20px;
  .upload-container {
    cursor: pointer;
  }
  .dropzone-inner {
    width: 100%;
    height: 315px;
    background: #fff;
    border: 2px dashed #B4B4B4;;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .upload-text {
    font-weight: $bold;
    font-size: 14px;
    line-height: 33px;
    text-align: center;
    color: $black;
    margin-bottom: 5px;
  }
  .btn-upload {
    min-width: 177px;
  }
}
// Dropzone

.table-caption {
  font-weight: $bold;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
  margin-bottom: 23px;
  .btn-view-all {
    font-size: 12px;
    color: #000000;
    margin: 0 12px;
    display: inline-block;
    &:hover {
      text-decoration:none;
    }
    svg {
      margin-left: 7px;
    }
  }
}
// Table
.custom-table {
 td,th{
    &:first-child{
      padding-left: 26px;
    }
  }
  &.table td,
  &.table th{
    border-top: 0px solid #dee2e6;
    max-width: 230px;
    line-height: 24px;
    white-space: nowrap;
    @media (max-width: 767px){
     min-width: 143px;
   }
  }
  thead {
    th {
      font-weight: $medium;
      font-size: 14px;
      line-height: 17px;
      color: #000000;
      border-bottom: 0 solid #dee2e6;
      padding: .75rem 10px;
      text-align: left;
      cursor: pointer;
      @media (max-width: 1250px){
        font-size: 12px;
      }
    }
  }
  tbody {
    background: #FFFFFF;
    box-shadow: 5px 5px 20px #E5E5E5;
    border-radius: 6px;
    td {
      font-weight: $medium;
      font-size: 16px;
      line-height: 68px;
      height: 65px;
      vertical-align: middle;
      padding: 0 10px;
      text-align: left;
      word-break: break-all;
      @media (max-width: 1250px ){
        font-size: 14px;
      }
      a {
        color: $black;
        text-decoration: underline;
      }
    }   
  }
  tbody tr {
    &:nth-child(even) {
      background-color: #FAFAFA;
    }
  }
  .table-column-title,
  .table-column-sorter {
    display: table-cell;
    vertical-align: middle;
  }
  .table-column-sorter{
    svg {
      margin-left: 5px;
    }
  }
   td,th{
      svg{
      margin-left: 15px;
    }
  }
}
.custom-table-secondary {
  margin-bottom: 0;
  .custom-table-title{
    font-weight: $medium;
    font-size: 16px;
    line-height: 23px;
    color: $black;
    &.custom-table-title-sm {
      font-size: 10px;
      display: block;
    }
  }
  &.table td,
  &.table th{
    border-top: 0px solid #dee2e6;
  }
  tbody {
    td {
      font-weight: $medium;
      font-size: 14px;
      color: #000000;
      vertical-align: middle;
      padding: 20px 25px;
      white-space: nowrap;
      a {
        color: $black;
        text-decoration: underline;
      }
    }
  }
  tbody tr {
    &:nth-child(even) {
      background-color: #FAFAFA;
    }
  }
}
// Fixed Header table
// .fixTableHead {
//   overflow-y: auto;
//   height: 110px;
// }
.fixTableHead > thead th {
  position: sticky !important;
  top: 0;
  z-index: 9;
}
table {
  border-collapse: collapse;        
  width: 100%;
}
// th,
// td {
//   padding: 8px 15px;
//   border: 2px solid #529432;
// }
th {
  background-color: #fff;
}
// Table

.thumb-preview {
  width: 80px;
  min-width: 80px;
  height: 50px;
  border-radius: 0px;
  background-color: #e5e5e5;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.gray-btn-status,
.red-btn-status,
.btn-for-code,
.btn-for-cash,
.btn-for-cc,
.btn-for-terminal,
.btn-for-account,
.btn-for-credit-memo,
.btn-status {
  font-weight: $medium;
  font-size: 12px;
  line-height: 14px;
  color: #fff;
  border-radius: 50px;
  min-width: 106px;
  min-height: 30px;
  padding: 0px 15px;
  &:hover {
    color: #fff;
  }
}

.btn-for-overdue
 {
  font-weight: $medium;
  font-size: 12px;
  line-height: 14px;
  border-radius: 50px;
  min-width: 106px;
  min-height: 30px;
  &:hover {
    color: #F90B0B;
  }
}
.gray-btn-status{
  background-color: #A6A6A6;
  color: #fff;
}
.red-btn-status{
  color: #FFF;
  background-color: #FF0000;
}
.btn-for-code {
  background: #24B4FF;
}
.btn-for-terminal{
  background: #37B1E6;
}
.btn-for-cc{
  background: #FFA300;
}
.btn-for-cash {
  background: #79C026;
}
.btn-for-credit-memo{
  background: #002a64;
}
.btn-for-overdue {
  background: rgba(249, 11, 11, 0.2);
  color: #F90B0B;
}
.btn-for-account{
  background: #3f000f;
}
.btn-green{
  background-color: #00C577;
  color: #fff;
}
.btn-yellow{
  background-color:  $yellow;
  color: $navy;
}
.btn-voided{
  background-color: #383838;
  color: #fff;
}
.btn-blue{
  background-color: #326AF1;
  color: #fff;
}
.status-btn{
  font-weight: 700;
  font-size: 12px !important;
  line-height: 14px !important;
  letter-spacing: 0.16px;
  border-radius: 55px;
  padding: 3px 10px;
  min-width: 75px;
}
.btn-back-header {
  padding: 0;
  margin: 22px 0px;
}
.btn-back {
  font-size: 18px;
  padding-left: 0px;
  a{
    color: #000;
    text-decoration: none;
  }
  svg {
    margin-right: 13px;
  }
}

.progress {
  height: 6px;
  margin-bottom: 10px;
  .progress-bar {
    background-color: $black;
  }
}

.custome__container{
  width: 100%;
  max-width: 1041px;
  margin: 0 auto;
  padding: 0 0px 50px;
}

// calendar input Css::Start
  .calendar__form{
    display: flex;
    justify-content: space-between;
    .form-control{
      padding-top: 30px;
      padding-left: 10px;
      background-image: url("./../images/calendarIcon.svg");
      background-repeat: no-repeat;
      background-position: 10px 30px;
      padding-left: 54px;
    }
    .input-from,.input-to{
      position: relative;
      width: 100%;
      max-width: 48%;
      label{
        font-weight: $bold;
        position: absolute;
        top: 26px;
        left: 10px;
        font-size: 14px;
        line-height: 17px;
        color: #000000;
        margin-bottom: 0;
      }
    }
  }
// calendar input Css::End
// Custome Tabs Css::Start
.custome__tabs{
    background: #FFFFFF;
    box-shadow: 5px 5px 20px #E5E5E5;
    border-radius: 6px;
    margin-top: 13px;
    margin-bottom: 25px;
    .custome__tabs-list{
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 0;
      margin: 0;
      li{
        width: 100%;
        max-width: 100%;
        a,span{
          padding: 22px 5px;
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          color: $black;
          text-align: center;
          position: relative;
          border-right: 1px solid #D8D8D8;
          display: block;
          text-decoration: none;
          cursor: pointer;
          &.active::after {
            content: '';
            height: 4px;
            width: 100%;
            display: block;
            position: absolute;
            left: 0;
            bottom: -1px;
            background-color: $black;
          }
        }
        &:last-child a{
          border-right: 0px;
        } 
        &:last-child span{
          border-right: 0px;
        }               
      }
    }
  }
// Custome Tabs Css::End
// Invoce table Css::Start
.invoce__table{
  .card {
    .card-body{
      padding: 0px;
    }
  }
  .btn-back-header{
    margin: 0px 0 59px 0;
    span{
      margin-left: 20px;
      font-weight: $bold;
      font-size: 22px;
      line-height: 26px;
      color: #000000;
    }
  }
  .cashlog__details-invoice{  
    .invoice__heading{
      background-color: #F5F5F5;
      padding: 17px 30px;
      font-weight: $bold;
      font-size: 16px;
      line-height: 19px;
      color: #000000;
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
    }
    .total__credit{
      font-weight: $bold;
      font-size: 22px;
      line-height: 26px;
      color: #000000;
      padding: 15px 40px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
  .invoice__title{
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    margin-bottom: 11px;
  }
  .invoice__sub-title{
    font-size: 16px;
    line-height: 19px;
    color: #000000;
    margin: 0;
  } 
  .invoice__right{
    display: flex;
    align-items: center;
    >div{
      margin-left: 50px;
    }
  }
  .transactions__list{
    padding: 0px;
    li{
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 17px 40px 17px 70px; 
      border-bottom: 1px solid #E5E5E5;
    }   
  }
  .transactions__title{
    font-weight: $bold;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
    margin-bottom: 11px;
  }
  .transactions__sub-title{
    font-size: 16px;
    line-height: 19px;
    color: #000000;
    margin: 0px;
    word-break: break-all;
  }
  .text-amount{
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    text-align: right;
    margin: 0px;
  }
  .text-green{
    color: $green;
  }
  .card-footer{
    background-color: #F5F5F5;
    border: 0px;
    height: 54px;
    padding: 15px 42px;
    .transactions__title{
      margin: 0px;
    }
  }
}
// Invoce table Css::End
.page-header{
  margin-bottom: 19px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 767px) {
    flex-direction: column;
    align-items: start;
  }
  .heading-title {
    font-weight: $exbold;
    font-size: 32px;
    line-height: 38px;
    color: $black;
    margin-bottom: 0px;
    @media (max-width: 568px) {
      margin-bottom: 15px;
    }    
    @media (max-width: 1024px) {
      font-size: 17px;
      line-height: normal;
    }     
  }
  
  .btn-filter{
    min-width: 176px;
    height: 48px;
    margin-left: 20px;
  }
  .form-group{
    margin-bottom: 0px;
  }
}
.page-header-btn-grp{
  width: 100%;
  max-width: 440px;
  text-align: right;
  @media (max-width: 568px){
    display: flex;
    flex-direction: column;
    margin-top: 20px;
  } 
  .btn-exportorders{
    min-width: 196px;
    height: 48px;
    @media (max-width: 1024px){
      min-width: auto;
      margin-left: 9px !important;
    }       
    @media (max-width: 568px){
      margin: 0 0 15px 0px !important;
    } 
  }  
}
.text-signature{
  font-family: 'Parisienne';
}
.text-bold{
  font-weight: $bold !important;
}
// autocomplete Css::Start
.autocomplete-dropdown-container{
  position: absolute;
  top: 70px;
  z-index: 9;
  width: 100%;
  background-color:$white;
  border: 0;
  // box-shadow: 5px 5px 20px #E5E5E5;
  border-radius: 4px;
  padding: 15px;
  .suggestion-item,.suggestion-item--active{
    padding: 10px 0px;
  }
}
// autocomplete Css::end
// Delete Modal Css::Start
  .delete_modal p{
    font-weight: normal;
    font-size: 16px;
    line-height: 26px;
    color: #000000;
  }
  .delete_modal .react-modal-dialog{
    width: 100%;
    max-width: 531px;
  }
  .delete_modal .react-modal-header{
    padding: 20px 20px 13px 25px;
    border-bottom: 0px;
  }
  .delete_modal .react-modal-body{
    padding: 0px 25px 25px 25px;
  }
  .delete_modal .delete-btn{
    margin-top: 4px;
  }
  .delete_modal{
    min-height: 10px !important;
  }
// Delete Modal Css::End
//chooseweight modal Css::Start
.chooseweight_modal{
  .react-modal-dialog{
    max-width: 621px;
    @media (min-width: 812px){
      width: 621px;
    }
  }
  .react-modal-header{
    // border: 0px;
    // padding-top: 18px;
    border: 0px;
    padding-top: 18px;
    padding: 18px 77px 20px 40px;
    // @media (min-width: 568px){
    //   padding-right: 77px;
    // }
    p{
      margin-top: 9px;
      margin-bottom: 0px;
      font-family: $Pangram;
      font-weight: normal;
      font-size: 16px;
      line-height: 22px;
      color: $black;
    }
  }
  .react-modal-body{
    // padding: 0px;
    padding: 0px;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    overflow: hidden;
    .select_driver-list{
      border-radius: 0px;
      box-shadow: none;
      li{
        padding: 0 40px;
      }
    }
    .driver__name{
      font-family: DS-Digital;
      font-weight: normal;
      font-size: 22px;
      line-height: 83px;
      color: #000;
    }
    .select_driver-list {
      .active {
        .driver__name{
          color: $green;
        }
      }
    }
  }
  .radio__checkbox{
    position: absolute;
    right: 0;
    top: -4px;
    width: 100%;
    height: 100%;
    .checkmark{
      width: 40px !important;
      height: 40px !important;
      border-radius: 50px !important;
      right: 23px !important;
      left: auto;
      top: 33%;
      &:after{
        left: 14px !important;
        top: 9px !important;
        width: 10px;
        height: 15px;     
      }
    }
  } 
}
//chooseweight modal Css::End
.table__last-child{
  max-width: 37px !important;
  min-width: 37px !important;
  text-align: right!important;
  margin-left: auto;
  padding: 0 !important;
  button{
    margin: 0;
    padding: 0 13px;
  }
}
.dots-dropdown_link{
  left: auto !important;
  right: 44px; 
  @media (min-width: 2200px){
    right: 34px;
  }
 
  @media only screen and (max-width:2200px) and (min-width: 1640px)  {
    right: 126px;
    // right: 34px;
  }
  @media only screen and (max-width:1640px) and (min-width: 1440px)  {
    right: 114px;
  }
  @media only screen and (max-width:1440px) and (min-width: 1280px)  {
    right: 34px;
  }
  @media only screen and (max-width:1280px) and (min-width: 1150px)  {
    right: 34px;
  }
  @media only screen and (max-width:1150px) and (min-width: 1024px)  {
    right: 34px;
  }
  @media only screen and (max-width:1024px) and (min-width: 768px)  {
    right: 33px;
  }
  // @media (min-width: 1280px){
  //   right: 50px;
  // }
  // @media (max-width: 1024px){
  //   right: 33px;
  // }
  @media (max-width: 414px){
    right: 90px;
  }

  .ant-dropdown-menu-item{
    color: #979797;
  }
}
// list Css:start
.complete__outbound-list{
  margin: 0px 0px 20px;
  padding: 0px;
  li{
    font-weight:$medium;
    font-size: 16px;
    line-height: 29px;
    color: #000000;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
// list Css:end
// pending date picker Css::Start
.pendingdate__picker{
  border: 2px solid #E5E5E5;
  min-height: 66px;
  border-radius: 4px;
  color: #000;
  padding: .375rem 14px;
  font-size: 16px;
  line-height: 19px;
  background: #fff url(../images/select-icon.svg) no-repeat right 22px center;
  background-size: 16px;
  padding-right: 56px;
  width: 100%;
  max-width: 385px;
  cursor: pointer;
  .pendingdate__picker-input{
    margin-top: 7px;
  }
  .DateRangePicker{
    position: absolute;
    right: 0px;
    top: 70px;
    z-index: 99;
    background: #fff;
    border: 0px solid #E5E5E5;
    box-shadow: 5px 5px 20px #E5E5E5;
    border-radius: 6px;
  }
  .material-textfield-label{
    position: unset;
    font-family: $Pangram;
    font-weight: $bold;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
  }
  .DateRangePicker__PaginationArrow{
    display: none;
  }
  .DateRangePicker__CalendarSelection--is-pending{
    background-color: $green;
  }
  .DateRangePicker__CalendarSelection{
    background-color: $green;
    border: 1px solid $green;
    &:hover,&:active,&:focus,&:visited{
      border: 1px solid $green;
    }
  }
  .DateRangePicker__CalendarHighlight--single{
    border: 1px solid $green;
  }
  .DateRangePicker__Date--is-selected{
    color: #000;
  }
  .DateRangePicker__MonthHeader{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
// pending date picker Css::End
.state__addresss{
  font-size: 12px;
}
// Light Box Css::Start
.ril__inner{
  .ril-image-current,.ril__image{
    max-width: 650px;
    margin: 0 auto;
    top: 50%;
    left: 50%;
    right: auto;
    transform: translate(-50%, -50%) !important;
  }
}
// Light Box Css::End
// common confirmtion popu Css::Start
.common__confirmtion-popu{
    padding-top: 23px;
  .react-modal-close{
    top: 10px;
  }
  .btn-back{
    font-family: $Pangram;
    font-weight: $bold;
    font-size: 18px;
    line-height: 22px;
    color: $black;
  }
  .react-modal-header{
    padding: 0 21px;
    border: 0;
    .btn-back-header {
      padding: 0px 0px 24px;
    }
  }
  .react-modal-title{
    margin-bottom: 12px;
    font-family: $Pangram;
    font-weight: $bold;
    font-size: 22px;
    line-height: 26px;
    color: $black;
    margin-bottom: 10px;
  }
  .react-modal-body{
    padding: 0px 47px 28px 47px; 
    p{
      font-family: $Pangram;
      font-size: 16px;
      line-height: 22px;
      color: $black;
      margin: 0px;
    }
    .material-textfield{
      margin-top: 20px;
    }
  }
  .amout__title{
    font-weight: $exbold;
    font-size: 48px;
    line-height: 58px;
    color: $black;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .bg-red{
    background-color:#F91111;
    border-color: #F91111;
    margin-top: 18px;
  }
}

// common confirmtion popu Css::End
.impersonate-header {
  margin: 0;
  width: 100%;
  text-align: center;
  border-radius: 0;
  background-color: #F90B0B;
  border: 0;
  padding: 15px 0;
}
.topnavigation-layout-header,
.impersonate-header {
  z-index: 99999999999999;
}
.impersonate-header span {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #FFFFFF;
  @media (max-width: 667px){
    display: block;
  }
}
.impersonate-header span a{
  text-decoration: underline;
  color: #fff;
}
.impersonate-header.sticky-impersonate {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9;
}

.m-10 {
  margin-bottom:10px
}
.mb-10{
  margin-bottom: 10px;
}
.notes {
  white-space: pre-wrap;
}
.box-shadow{
  box-shadow: 5px 5px 20px #E5E5E5;
}

.creat__order-drawer{
  .license__image-title{
    font-weight: 500;
    font-size: 14px;
    line-height: 17px; 
    color: #000000;
    margin-bottom: 10px;
  }
  .truck__img {
    .react-html5-camera-photo>img, .react-html5-camera-photo>video{
      width: 100% !important;
    }
    img{
      width: 100%;
    }
  }
}
.blackText {
  color:#000;
}


.btn-icon {
  &.red {
    cursor: pointer;
    color: red;
  }
  &.green {
    color: #00e700;
  }
}
.setupqb{
  white-space: nowrap;
  margin: auto;
  line-height: 34px;
}

.mt-custom{
	margin-top: 13px;
}
.toggle-title{
  font-size:medium;
  padding-left: 1rem;
  font-weight: lighter;
  vertical-align: middle;
}

.search-check-list{
  display: flex;
  align-items: center;
  padding-left: 15px;
  padding-top: 10px;
}
.search-check-list-items{
  padding-left: 2px;
  margin-top: 4px;
  &:not(last-child){
    margin-right: 10px;
  }
  .custom-checkbox-line{
    padding-left: 30px;
    .checkmark{
      height: 20px;
      width: 20px;
      border-color: #E5E5E5;
      &:after {
        left: 6px;
        top: 0px;
        height: 12px;
      }
    }
  }
}
.select_driver-list-spiner{
  width: 100%;
  height: 100%;
  z-index: 9;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  position: absolute;
  display: grid;
  place-content: center;
  .overlay{
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 9;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    background-color: #00000047;
    z-index: -1;
  }
  .anticon{
    font-size: 27px !important;
    color: #000;
  }
}
.ant-spin-dot{
  color: #000;
}
.scrollable-list-container{
  max-height: 250px;
  overflow: auto;
}
.handing-fee-lable{
  margin-top: -12px;
}
.rounded-text {
line-height: normal;
}


.spin-parant {
  .ant-spin-container {
    opacity: 1 !important;
  }
}

.ant-spin-container {
  opacity: 1 !important;
}