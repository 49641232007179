@import './theme/global.scss';
@import './theme/variable.scss';
body {
  font-family: $Pangram, sans-serif;
  font-size: 14px;
  line-height: 17px;
  color: $black;
}
.payengine-field input,
.payengine-field iframe {
    border: 2px solid #E5E5E5 !important;
    height: 66px !important;
    border-radius: 4px !important;
    color: #000;
    padding: .375rem 14px !important;
    width: 100% !important;
}
.card__number{
  display: flex !important;
  img{
  margin-right: 10px;
  }
  span{
    margin-left: 10px;
  }
}
// iframe{
//   z-index: -1 !important;
// }
