@import "../../theme/variable.scss";
.complete__details-wrapper {}
/* @media print {
  .no-page-break {
    page-break-before: avoid;
    page-break-after: avoid;
  }
} */
@media print {
  .no-page-break {
    overflow: hidden; /* or overflow: auto; */
  }
  .print-order-modal {
    .react-modal-body {
      padding: 0 !important;
    }
  }
}
